.register-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.captcha {
  width: 100%;
  padding-left: 4px;
  padding-right: 4px;
  padding-bottom:4px;
  padding-top: 20px;
}
.register-disclaimer {
  font-style: italic;
  padding-bottom:4px;
  padding-top: 4px;
}
.register-banner {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fourunits);
  background-size: cover;
  justify-content: space-between;
  background-position: center;
}
.register-text {
  font-size: 0.75rem;
  text-align: center;
  font-weight: 600;
  margin-bottom: var(--dl-space-space-twounits);
  letter-spacing: 2px;
}
.register-text-impressum {
  font-size: 1rem;
  text-align: left;
  font-weight: 600;
  margin-bottom: var(--dl-space-space-twounits);
  letter-spacing: 2px;
}

.impressum-banner {
  width: 100%;
  display: flex;
  align-items: left;
  padding-top: var(--dl-space-space-unit);
  padding-left: 125px;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fourunits);
  background-size: cover;
  justify-content: space-between;
  background-position: center;
  font-family: "Inter";
}

.register-label {
  padding: 10px 0;
  display: inline-block;
}
.register-text01 {
  text-transform: uppercase;
}
.register-text01-impressum {
  text-transform: uppercase;
  text-align: left;
}
.register-text02 {
  font-size: 3rem;
  text-align: center;
  margin-bottom: var(--dl-space-space-unit);
}
.register-text03 {
  max-width: var(--dl-size-size-maxwidth);
  text-align: center;
  margin-bottom: var(--dl-space-space-twounits);
}
.register-container1 {
  flex: 0 0 auto;
  width: 50%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  padding-left: 4px;
  padding-right: 4px;
}
.register-textinput {
  font-size: 0.75rem;
  border-color: var(--dl-color-gray-500);
  margin-bottom:6px;
  width: 100%;
  height: 3.5rem;
  padding-left: 4px;
  padding-right: 4px;
}
.register-select {
  font-size: 0.75rem;
  border-color: var(--dl-color-gray-500);
  margin-bottom:5px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: var(--dl-color-gray-700);
  height: 3rem;
  width: 100%;
}
.register-button {
  color: var(--dl-color-gray-white);
  font-size: 0.75rem;
  transition: 0.3s;
  background-color: rgba(41, 20, 119, 0.9);
  margin-bottom:5px;
  height: 3rem;
  width: 100%;
}
.register-button:hover {
  color: var(--dl-color-gray-black);
  background-color: transparent;
}
.home-register-r {
  color: var(--dl-color-gray-black);
  transition: 0.3s;
  font-weight: bold;
  border-color: var(--dl-color-primary1-blue100);
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  width: 100%;
  height: 3.5rem;
  border-radius: 45px;
  margin-top: 1em;
  background-color: var(--dl-color-success-300);
}
.home-register-r:hover {
  border-color: rgba(41, 20, 119, 0.9);
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  background-color: rgba(41, 20, 119, 0.9);
  color: white;
}
@media(max-width: 991px) {
  .register-text03 {
    width: 100%;
  }
}
@media(max-width: 767px) {
  .register-banner {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .register-text03 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
}
@media(max-width: 479px) {
  .register-banner {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .register-container1 {
    height: 614px;
    align-items: center;
    flex-direction: column;
  }
  .register-button {
    margin-top: var(--dl-space-space-unit);
    margin-left: 0px;
  }
  .styleoption {
    font-size: 0.75rem;
    font-weight: bold;
    background-color: red;
  }
  .impressum-banner {
    padding-left: 40px;   
  }
  .register-text02 {
    font-size: 2.5rem;
  }
  .register-disclaimer {
    font-style: italic;
    padding-bottom: 10px;
  }
}


.impressum-banner h4, h2, p {
  margin-bottom: 25px;
}
.impressum-banner ul {
  list-style: none;
  margin-left: 20px;
}
.impressum-banner a {
  color: blue;
  text-decoration: underline;
}

.impressum-title  {
  text-align: center;
  font-size: 25px;
  margin-bottom: 25px;
}